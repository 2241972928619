// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tabs: [],
    roles: [],
    permissions: [],
  },

  mutations: {
    addTab(state, tab) {
      state.tabs.push(tab);
    },
    removeTab(state, tabName) {
      state.tabs = state.tabs.filter(tab => tab.name !== tabName);
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },
  actions: {
    // 获取用户信息
    // GetInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     listUserRoleMenu().then(res => {
    //       console.log(res)
    //       // const user = res.user
    //       // const avatar = (user.avatar == "" || user.avatar == null) ? require() : process.env.VUE_APP_BASE_API + user.avatar;
    //       if (res.data && res.data.length > 0) { // 验证返回的roles是否是一个非空数组
    //         commit('SET_ROLES', res.data)
    //         commit('SET_PERMISSIONS', res.data)
    //         sessionStorage.setItem('menu', JSON.stringify(res.data))
    //       } else {
    //         commit('SET_ROLES', ['ROLE_DEFAULT'])
    //       }
    //       // commit('SET_NAME', user.userName)
    //       // commit('SET_AVATAR', avatar)
    //       resolve(res)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
  }
});